var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex-column"},[_c('el-row',{staticStyle:{"margin":"10px 0px 15px 5px !important"},attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"heading"},[_vm._v("From Club")]),_c('el-select',{attrs:{"placeholder":"Select a club","clearable":"","filterable":""},model:{value:(_vm.inputVal.fromClub),callback:function ($$v) {_vm.$set(_vm.inputVal, "fromClub", $$v)},expression:"inputVal.fromClub"}},_vm._l((_vm.fromClubs),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('el-option',{key:_id,attrs:{"label":name,"value":_id}})}),1)],1),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"heading"},[_vm._v("To Club")]),_c('el-select',{attrs:{"placeholder":"Select a club","clearable":"","filterable":""},model:{value:(_vm.inputVal.toClub),callback:function ($$v) {_vm.$set(_vm.inputVal, "toClub", $$v)},expression:"inputVal.toClub"}},_vm._l((_vm.toClubs),function(ref){
var _id = ref._id;
var name = ref.name;
return _c('el-option',{key:_id,attrs:{"label":name,"value":_id}})}),1)],1)],1),_c('CheckBoxGroup',{attrs:{"choices":[
      { name: 'Male', type: 'Male' },
      { name: 'Female', type: 'Female' },
      { name: 'All', type: 'All' } ],"min":0,"max":1,"name":"Gender"},model:{value:(_vm.inputVal.gender),callback:function ($$v) {_vm.$set(_vm.inputVal, "gender", $$v)},expression:"inputVal.gender"}}),_c('CheckBoxGroup',{attrs:{"choices":_vm.ageLevels,"min":0,"name":"Age Levels"},model:{value:(_vm.inputVal.ageLvl),callback:function ($$v) {_vm.$set(_vm.inputVal, "ageLvl", $$v)},expression:"inputVal.ageLvl"}}),_c('CheckBoxGroup',{attrs:{"choices":[
      { name: 'Clearance', type: 'clearance' },
      { name: 'Permit', type: 'permit' } ],"min":0,"max":1,"name":"Type"},model:{value:(_vm.inputVal.type),callback:function ($$v) {_vm.$set(_vm.inputVal, "type", $$v)},expression:"inputVal.type"}}),_c('CheckBoxGroup',{attrs:{"choices":this.clearancePermitStatuses,"min":0,"max":1,"name":"Status"},model:{value:(_vm.inputVal.status),callback:function ($$v) {_vm.$set(_vm.inputVal, "status", $$v)},expression:"inputVal.status"}}),_c('CheckBoxGroup',{attrs:{"choices":[
      { name: 'Yes', type: 'yes' },
      { name: 'No', type: 'no' } ],"min":0,"max":1,"name":"Requires Action"},model:{value:(_vm.inputVal.requiresAction),callback:function ($$v) {_vm.$set(_vm.inputVal, "requiresAction", $$v)},expression:"inputVal.requiresAction"}}),_c('CheckBoxGroup',{attrs:{"choices":[
      { name: 'Ascending', type: 'ascending' },
      { name: 'Descending', type: 'descending' } ],"min":0,"max":1,"name":"Clearance ID Order"},model:{value:(_vm.inputVal.clearanceIDOrder),callback:function ($$v) {_vm.$set(_vm.inputVal, "clearanceIDOrder", $$v)},expression:"inputVal.clearanceIDOrder"}}),_c('div',{staticClass:"mt-2 d-flex-column"},[_c('div',{staticClass:"m-1"},[_c('el-button',{staticClass:"full-width button-invisible",on:{"click":_vm.clear}},[_vm._v("Clear")]),_c('el-button',{staticClass:"full-width",attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("Apply Filters")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }