<template>
  <div>
    <p class="mb-1">Competition: {{ competitionName }}</p>
    <el-tabs type="card">
      <el-tab-pane label="Competition Details">
        <el-form
          :disabled="
            (((!isTFA && competition.season !== currentSeason) ||
              ![currentSeason, currentSeason - 1].includes(competition.season)) &&
              !isSuperAdminTFA &&
              !isSuperAdminNRL &&
              !competition.isActive) ||
            (!isSuperAdminTFA && !isSuperAdminNRL && competition.expired) ||
            readOnly
          "
          label-position="top"
          :model="competition"
          :rules="competitionRules"
          id="competitionRules"
          ref="competitionRules"
        >
          <div class="tw-flex tw-items-start tw-gap-3 tw-py-3">
            <el-col :span="6" class="text-center avatar-box entity-card">
              <img
                v-if="currentLogo"
                :src="currentLogo"
                @error="displayPlaceholder"
                alt=""
                class="avatar upload-img"
              />
              <img v-else src="~@/assets/shield_black_small.svg" alt="" class="avatar upload-img" />
              <vue-upload-component
                v-if="this.$route.params.type !== 'insert'"
                ref="upload"
                :drop="true"
                v-model="files"
                :extensions="extensions"
                :accept="accept"
                :post-action="uploadURL"
                :headers="uploadHeaders"
                :data="competitionData"
                :multiple="true"
                @input-file="inputFile"
                @input-filter="inputFilter"
                class="el-button el-button--default upload upload-btn"
              >
                Upload
              </vue-upload-component>
            </el-col>
            <el-col
              :span="18"
              class="entity-card"
              v-if="
                this.$route.params.id !== 'add' && (isTFA || (!isTFA && rlPermissionedAssociation))
              "
            >
              <el-form-item label="Competition Registration Link">
                <el-input
                  type="text"
                  autocomplete="off"
                  v-model="compPrivateFinderLink"
                  readonly
                  disabled=""
                >
                  <template slot="append">
                    <el-button
                      v-clipboard="compPrivateFinderLink"
                      @click="$customSuccess('Successfully copied to clipboard')"
                    >
                      Copy
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
            <el-col :span="8">
              <el-form-item prop="name" label="Competition Name">
                <el-input
                  autocomplete="off"
                  placeholder="Enter competition name"
                  v-model="competitionName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="association" label="Association">
                <el-select
                  v-model="association"
                  filterable
                  placeholder="Select a association"
                  @change="getCompetitionTypes"
                >
                  <el-option
                    v-for="item in allAssociations"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="!isTFA">
              <el-form-item prop="competitionType" label="Competition Type">
                <el-select
                  v-model="competitionType"
                  filterable
                  clearable
                  placeholder="Select Competition Type"
                  @change="handleCompetitionTypeChange(true)"
                >
                  <el-option
                    v-for="competitionType in competitionTypes"
                    :label="competitionType.name"
                    :key="competitionType._id"
                    :value="competitionType._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="type" label="Team List Type" v-if="!isTFA">
                <el-select
                  v-model="competition.type"
                  filterable
                  placeholder="Select a team list type"
                >
                  <el-option
                    v-for="item in allCompTypes"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="type" label="Competition Type" v-if="isTFA">
                <el-select
                  v-model="competition.type"
                  filterable
                  placeholder="Select a competition type"
                >
                  <el-option
                    v-for="item in allCompTypes"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
            <el-col :span="3" v-show="isSuperAdminNRL">
              <el-form-item prop="season" label="Season">
                <el-input
                  autocomplete="off"
                  placeholder="Enter a season"
                  type="number"
                  v-model.number="competition.season"
                  :disabled="
                    selectedCompetitionType &&
                    (selectedCompetitionType.isAllowedToUseFinals === false ||
                      selectedCompetitionType.isAllowedToScore === false)
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" v-show="!isSuperAdminNRL">
              <el-form-item prop="season" label="Season">
                <el-input
                  autocomplete="off"
                  placeholder="Enter a season"
                  type="number"
                  v-model.number="competition.season"
                  :disabled="!isSuperAdminTFA"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              :span="3"
              v-if="
                competition.orgtree &&
                competition.orgtree.national &&
                competition.orgtree.national._id === 31
              "
            >
              <el-form-item prop="seasonTag" label="Season Tag">
                <el-select v-model="competition.seasonTag">
                  <el-option
                    v-for="tag in seasonTags"
                    :key="tag._id"
                    :label="tag.name"
                    :value="tag._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="ageLvl" label="Age Level">
                <el-select
                  v-model="competition.ageLvl"
                  filterable
                  placeholder="Select an age level"
                >
                  <el-option
                    v-for="item in ageLevels.map((l) => l.name)"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="regularSeasonRounds" label="Regular Season Rounds">
                <el-input
                  autocomplete="off"
                  placeholder="Enter number of regular season rounds"
                  type="number"
                  v-model.number="competition.regularSeasonRounds"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="finalSeasonRounds" label="Final Season Rounds">
                <el-input
                  autocomplete="off"
                  placeholder="Enter number of final season rounds"
                  type="number"
                  v-model.number="competition.finalSeasonRounds"
                  :disabled="
                    selectedCompetitionType &&
                    selectedCompetitionType.isAllowedToUseFinals === false
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="finalsType" label="Finals type">
                <el-select
                  v-model="competition.finalsType"
                  filterable
                  placeholder="Select a finals type"
                  :disabled="
                    selectedCompetitionType &&
                    selectedCompetitionType.isAllowedToUseFinals === false
                  "
                >
                  <el-option
                    v-for="item in allFinalTemplates"
                    :key="item.type"
                    :label="item.type"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
            <el-col :span="8">
              <el-form-item prop="periods" label="Periods">
                <el-input-number
                  :controls="false"
                  :precision="0"
                  :min="1"
                  :max="4"
                  autocomplete="off"
                  placeholder="No. of periods"
                  v-model.number="competition.periods"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="periodLength" label="Period Length">
                <el-input
                  autocomplete="off"
                  placeholder="Period length"
                  type="number"
                  v-model.number="competition.periodLength"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="gender" label="Gender">
                <el-select
                  v-model="competition.gender"
                  filterable
                  placeholder="Select a competition gender"
                >
                  <el-option
                    v-for="item in ['Male', 'Female', 'All']"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <div class="tw-flex tw-flex-wrap tw-items-end tw-gap-3 tw-py-3">
            <div>
              <el-form-item prop="allowScoring" label="Allow Scoring">
                <el-select
                  v-model="competition.allowScoring"
                  filterable
                  placeholder="Allow Scoring"
                  :disabled="
                    selectedCompetitionType && selectedCompetitionType.isAllowedToScore === false
                  "
                >
                  <el-option
                    v-for="item in [
                      { name: 'Yes', value: true },
                      { name: 'No', value: false },
                    ]"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div :span="8">
              <el-form-item
                prop="noMatchesForFinalEligibility"
                label="No. of Matches for Finals Eligibility"
              >
                <el-input
                  v-model.number="competition.noMatchesForFinalEligibility"
                  placeholder="No. of Matches for Finals Eligibility"
                  type="number"
                  :disabled="
                    selectedCompetitionType &&
                    selectedCompetitionType.isAllowedToUseFinals === false
                  "
                />
              </el-form-item>
            </div>
            <div :span="8">
              <el-form-item prop="hasPools" label="Pool Competition?">
                <el-select
                  v-model="competition.hasPools"
                  filterable
                  placeholder="Is Pool Competition"
                >
                  <el-option
                    v-for="item in [
                      { name: 'Yes', value: true },
                      { name: 'No', value: false },
                    ]"
                    :key="item.name"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div :span="8">
              <el-form-item label="Max Number of Teams">
                <div v-if="isTFA || (!isTFA && rlPermissionedAssociation)" class="text-helper">
                  This field represents the maximum number of teams that are allowed to register to
                  this competition.
                </div>
                <el-input-number
                  :controls="false"
                  v-model="competition.meta.maxNoTeams"
                  :min="0"
                  :precision="0"
                >
                </el-input-number>
              </el-form-item>
            </div>
          </div>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
            <el-col :span="8">
              <el-form-item prop="isActive" label="Active">
                <el-select
                  v-model="competition.isActive"
                  :disabled="
                    ![
                      roles.associationAdmin,
                      roles.regionAdmin,
                      roles.stateAdmin,
                      roles.superAdmin,
                    ].includes(clearance)
                  "
                  @change="isActiveChange = true"
                >
                  <el-option label="Yes" :value="true" />
                  <el-option label="No" :value="false" />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <h4>Draw Builder Helpers</h4>
          <div class="tw-flex tw-items-end tw-gap-3 tw-py-3">
            <el-col :span="8">
              <el-form-item prop="defaultstartday" label="Default start day">
                <el-date-picker
                  type="date"
                  v-model="defaultStartDay"
                  format="dd/MM/yyyy"
                  placeholder="Select a default start day"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="defaultStartTime" label="Default Start Time">
                <el-time-picker
                  format="HH:mm"
                  value-format="HH:mm"
                  v-model="competition.drawbuilder.defaultStartTime"
                  :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                  }"
                  placeholder="Select a default start time"
                >
                </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="daysbetweenrounds" label="Days between rounds">
                <el-input
                  autocomplete="off"
                  placeholder="Enter number of days between rounds"
                  type="number"
                  v-model="daysBetweenRounds"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isTFA || (!isTFA && rlPermissionedAssociation)">
              <el-form-item prop="meta.venue._id" label="Venue">
                <el-select
                  v-model="competition.meta.venue._id"
                  :remote="true"
                  :remote-method="filter"
                  placeholder="Enter venue to start searching"
                  filterable
                >
                  <el-option
                    v-for="item in allvenues"
                    :label="`${item.name} ${
                      item.address && item.address.suburb
                        ? '- ' + item.address.suburb
                        : item.suburb
                        ? '- ' + item.suburb
                        : ''
                    }`"
                    :key="item.name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isTFA">
              <el-form-item prop="meta.numberOfFields" label="Number Of Fields">
                <el-input-number v-model="competition.meta.numberOfFields" :min="1" :precision="0">
                </el-input-number>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
        <action-buttons
          submit
          cancel
          @submit="
            isActiveChange === true && type === 'update'
              ? (isActiveUpdateDialog = true)
              : updateCompetition()
          "
          @cancel="cancelForm()"
        />
      </el-tab-pane>

      <el-tab-pane
        label="Teams Allocation"
        v-if="this.$route.params.id !== 'add' && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="2"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }} </label>
        </el-col>
        <team-allocation
          :key="`${competition.isActive}_${competition._id}`"
          :comp="competition"
          :allAssociations="allAssociations"
          :teamAlloc="teamAllocation"
          :voterAlloc="false"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Pools"
        v-if="this.$route.params.id !== 'add' && competition.hasPools && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="3"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }}</label>
        </el-col>
        <pools :comp="competition" />
      </el-tab-pane>
      <el-tab-pane
        label="Ladders"
        v-if="this.$route.params.id !== 'add' && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="4"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }}</label>
        </el-col>
        <ladder-template :competition="competition" :competitionType="selectedCompetitionType" />
      </el-tab-pane>

      <el-tab-pane
        label="Elite Details"
        v-if="(isSuperAdminNRL || isStateAdmin) && this.$route.params.id !== 'add' && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="5"
      >
        <elite :competition="competition" />
      </el-tab-pane>
      <el-tab-pane
        label="Additional Details"
        v-if="
          this.$route.params.id !== 'add' &&
          !readOnly &&
          showAdditionalDetails &&
          !(
            selectedCompetitionType &&
            selectedCompetitionType.isAllowedToUseAdditionalDetails === false
          )
        "
        :disabled="competition.expired"
        name="13"
      >
        <additional-details :competition="competition" />
      </el-tab-pane>
      <el-tab-pane
        label="Display Options"
        v-if="this.$route.params.id !== 'add' && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="6"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }}</label>
        </el-col>
        <display-options :competition="competition" :competitionType="selectedCompetitionType" />
      </el-tab-pane>

      <el-tab-pane
        label="Player Points"
        v-if="this.$route.params.id !== 'add' && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="7"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }}</label>
        </el-col>
        <player-points :comp="competition" />
      </el-tab-pane>
      <el-tab-pane
        label="Awards"
        v-if="isSuperAdminNRL && this.$route.params.id !== 'add' && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="8"
      >
        <awards :competition="competition" />
      </el-tab-pane>
      <el-tab-pane
        label="Awards Settings"
        v-if="
          (isSuperAdminNRL || isSuperAdminTFA || isStateAdmin || isAssociationAdmin) &&
          enableAwardsSettings
        "
        name="awards-settings"
      >
        <awards-settings :competition="competition" />
      </el-tab-pane>
      <el-tab-pane
        label="Voter Allocation"
        v-if="
          this.$route.params.id !== 'add' &&
          !isTFA &&
          this.competition.meta.elite &&
          this.competition.meta.enableAwards &&
          !readOnly
        "
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="9"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }} </label>
        </el-col>
        <team-allocation
          :key="`voter_${competition.isActive}_${competition._id}`"
          :comp="competition"
          :allAssociations="allAssociations"
          :teamAlloc="voterAllocation"
          :voterAlloc="true"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Awards Demerits"
        v-if="
          this.$route.params.id !== 'add' &&
          !isTFA &&
          this.competition.meta.elite &&
          this.competition.meta.enableAwards &&
          this.competition.meta.awardsSettings.demerits &&
          !readOnly
        "
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="10"
      >
        <el-row :gap="2">
          <el-col :span="24">
            <label class="el-form-item__label">{{ competitionName }} </label>
          </el-col>
          <el-col :span="8">
            <el-input
              autocomplete="off"
              placeholder="Name"
              v-model="search"
              @keyup.native.enter="searchMember"
            ></el-input>
          </el-col>
        </el-row>
        <!-- MEMBERS TABLE  -->
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="24">
            <el-table :data="members" border @current-change="selectMember" highlight-current-row>
              <el-table-column label="NRL ID" prop="_id._id" width="auto"> </el-table-column>
              <el-table-column label="Name" prop="_id.name" width="auto"> </el-table-column>
              <el-table-column fixed="right" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row._id._id === currentMember.player._id">
                    <i class="el-icon-check" style="font-size: 18px; color: green"></i>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
        <el-row gap="2">
          <el-col :span="8">
            <el-select
              v-model="currentMember.team.name"
              placeholder="Select Team"
              default-first-option
              filterable
              :disabled="!currentMember.player._id ? true : false"
              @change="teamSelect"
            >
              <el-option
                v-for="item in playerTeams"
                :label="item.name"
                :key="item.id"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-select
              v-model="currentMember.match._id"
              placeholder="Select Match"
              default-first-option
              filterable
              :disabled="!currentMember.team.name ? true : false"
              @change="matchSelect"
            >
              <el-option
                v-for="item in playerMatches"
                :label="item.match.name"
                :key="item.match._id"
                :value="item.match._id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-input-number
              placeholder="Enter amount of demerit points"
              v-model="currentMember.votes"
              :disabled="!currentMember.match._id ? true : false"
              :min="1"
            ></el-input-number>
          </el-col>
        </el-row>
        <el-button
          type="danger"
          class="tw-mt-3"
          :disabled="!currentMember.votes ? true : false"
          @click="deductPoints"
          >Deduct Points</el-button
        >
      </el-tab-pane>
      <!-- <el-tab-pane
        name="10"
        id="awardVotingTab"
        label="Award Voting (Quarterly)"
        v-if="
          this.$route.params.id !== 'add' &&
          !isTFA &&
          this.competition.meta.elite &&
          this.competition.meta.enableAwards &&
          this.competition.meta.awardsSettings.periodVoting &&
          this.competition.meta.awardsSettings.periodRanges &&
          this.competition.meta.awardsSettings.periodRanges.length
        "
      >
        <button
          @click="notifyJudges"
          class="notify-judges"
          style="
            color: #fff;
            background-color: #f56c6c;
            transition: 0.1s;
            font-weight: 500;
            -moz-user-select: none;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4px;
            border: solid 3px #f56c6c;
          "
        >
          Notify Judges
        </button>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-form label-position="top" :model="add" :rules="voterRules" ref="voterRules">
            <div class="tw-flex tw-items-end tw-gap-3 tw-pt-3 tw-border-r tw-border-gray-100">
              <el-col :span="12">
                <el-form-item prop="memberId" label="Voter Assignment">
                  <el-select v-model="add.memberId" filterable placeholder="Assign a voter">
                    <el-option
                      v-for="voter in availableVoters"
                      :key="voter._id"
                      :label="fullname(voter)"
                      :value="voter._id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="quarter" label="Select Quarter">
                  <el-select v-model="add.quarter" placeholder="Select Quarter">
                    <el-option disabled key="1-6" label="1-6" value="1-6"> </el-option>
                    aaaaaaa TODO: may need updating to appropriate round range
                    <el-option disabled key="7-13" label="7-13" value="7-13"> </el-option>
                    <el-option key="14-19" label="14-19" value="14-19"> </el-option>
                    <el-option disabled key="20-25" label="20-25" value="20-25"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </div>
          </el-form>
          <el-col :span="6" class="add-player-col">
            <el-button type="success" @click="handleAddVoter"> Add Voter </el-button>
          </el-col>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <data-tables
            v-if="newVoters.length > 0"
            :data="newVoters"
            :table-props="tableProps"
            :page-size="pageSize"
            @row-click="removeFromVoterList"
            id="voterlist"
            class="data-table"
          >
            <el-table-column label="Name">
              <template slot-scope="scope">
                {{ fullname(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column prop="votingOpen" label="Status">
              <template slot-scope="scope">
                {{ scope.row.votedOn ? "Votes Submitted" : "Pending" }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="" label="Actions">
              <template>
                <i class="el-icon-delete remove-icon"></i>
              </template>
            </el-table-column>
          </data-tables>
        </div>
        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="8">
            <el-select v-model="votingOpen" placeholder="Enable Voting">
              <el-option key="open" label="Open" :value="true"> </el-option>
              <el-option key="closed" label="Closed" :value="false"> </el-option>
            </el-select>
          </el-col>
        </div>

        <div class="tw-flex tw-items-end tw-gap-3 tw-py-3 tw-border-r tw-border-gray-100">
          <el-col :span="12">
            <el-button type="success" @click="submitVoterAssignment()">Save</el-button>
            <el-button type="default" @click="cancelForm()">Cancel</el-button>
          </el-col>
        </div>
      </el-tab-pane> -->
      <el-tab-pane
        name="12"
        id="otherAwardVotingTab"
        label="Awards One-Off Season Voting"
        :lazy="true"
        v-if="
          this.$route.params.id !== 'add' &&
          !readOnly &&
          !isTFA &&
          this.competition.meta.elite &&
          this.competition.meta.enableAwards &&
          ((this.competition.meta.awardsSettings.endOfSeasonVoting &&
            this.competition.meta.awardsSettings.endOfSeasonVoting.length) ||
            (this.competition.meta.awardsSettings.periodVoting &&
              this.competition.meta.awardsSettings.periodRanges &&
              this.competition.meta.awardsSettings.periodRanges.length))
        "
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
      >
        <assignment-allocation
          :assignmentTeam="awardTeam"
          :comp="competition"
          :assignmentAlloc="assignmentAllocation"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Referee Appointments"
        v-if="this.$route.params.id !== 'add' && isTFA && !readOnly"
        :disabled="!isSuperAdminTFA && !isSuperAdminNRL && competition.expired"
        name="11"
      >
        <el-col :span="24">
          <label class="el-form-item__label">{{ competitionName }} </label>
        </el-col>
        <team-allocation
          :key="`ref_${competition.isActive}_${competition._id}`"
          :comp="competition"
          :allAssociations="allAssociations"
          :teamAlloc="refAppointment"
          :refAlloc="true"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Appointments"
        v-if="this.$route.params.id !== 'add' && !isTFA && !readOnly"
        :disabled="!isSuperAdminNRL && competition.expired"
        name="15"
      >
        <appointments
          :key="`ref_${competition.isActive}_${competition._id}`"
          :comp="competition"
          :allAssociations="allAssociations"
          :teamAlloc="refAppointment"
          :refAlloc="true"
        />
      </el-tab-pane>
      <el-tab-pane
        label="Matches"
        v-if="this.$route.params.id !== 'add' && !isTFA"
        :disabled="!isSuperAdminNRL && competition.expired"
        name="17"
      >
        <matches-list
          :key="`ref_${competition.isActive}_${competition._id}`"
          :comp="competition"
          :allAssociations="allAssociations"
          :teamAlloc="refAppointment"
          :refAlloc="true"
        />
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="Warning" :visible.sync="isActiveUpdateDialog" width="40%">
      <div class="point-adjustment">
        <h4>
          Do you want to update the Active status for all teams allocated to this competition? The
          page will reload afterwards.
        </h4>
        <!-- <el-input-number v-model="pointsADJValue"></el-input-number> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            updateCompetition();
            isActiveUpdateDialog = false;
          "
          >No</el-button
        >
        <el-button type="primary" @click="updateCompetitionAndTeams">Yes</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { isInteger } from "lodash/fp";
import ActionButtons from "@/components/ActionButtons.vue";
import {
  currentSeason,
  ageLevels,
  roles,
  errormsg,
  seasonTags,
  finalTemplates,
  nationals,
  uploadURL,
} from "../../utils/constants";
import { EventBus } from "../../bus";
import Pools from "./Pools.vue";
import DisplayOptions from "./DisplayOptions.vue";
import TeamAllocation from "./TeamAllocation.vue";
import AssignmentAllocation from "./AssignmentAllocation.vue";
import LadderTemplate from "./LadderTemplate.vue";
import PlayerPoints from "./PlayerPoints.vue";
import Elite from "./Elite.vue";
import AdditionalDetails from "./AdditionalDetails.vue";
import Appointments from "./AppointmentRequests.vue";
import MatchesList from "./Matches.vue";
import Awards from "./Awards.vue";
import AwardsSettings from "./AwardsSettings.vue";

export default {
  name: "CompetitionsForm",
  components: {
    AssignmentAllocation,
    Elite,
    ActionButtons,
    Pools,
    DisplayOptions,
    TeamAllocation,
    LadderTemplate,
    PlayerPoints,
    Appointments,
    MatchesList,
    Awards,
    AdditionalDetails,
    AwardsSettings,
  },
  props: {},
  data() {
    const positiveIntZeroValidate = (obj, value, callback) => {
      if (!isInteger(value) || parseInt(value) < 0) {
        return callback(new Error(errormsg.gte_positive_zero));
      }
      return callback();
    };
    const positiveIntValidate = (obj, value, callback) => {
      if (!isInteger(value) || parseInt(value) <= 0) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const typeValidator = (obj, value, callback) => {
      if (!this.competition.type) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const compTypeValidator = (obj, value, callback) => {
      if (!this.competition.competitionType._id) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const assocValidator = (obj, value, callback) => {
      if (!this.competition.orgtree.association._id) {
        return callback(new Error(errormsg.select_option));
      }
      return callback();
    };
    const dbrValidator = (obj, value, callback) => {
      if (
        /* eslint-disable */
        isNaN(this.competition.drawbuilder.daysBetweenRounds) ||
        parseInt(this.competition.drawbuilder.daysBetweenRounds) < 0
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateDefaultStartDay = (rule, value, callback) => {
      if (
        !this.competition.drawbuilder.defaultStartDay ||
        this.competition.drawbuilder.defaultStartDay === ""
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const dstValidator = (obj, value, callback) => {
      if (
        !parseInt(this.competition.drawbuilder.defaultStartTime) ||
        this.competition.drawbuilder.defaultStartTime === ""
      ) {
        return callback(new Error(errormsg.input_option));
      }
      return callback();
    };
    const validateVenue = (rule, value, callback) => {
      if (!value || value === "") {
        return callback(new Error(""));
      }
      return callback();
    };
    return {
      selectedCompetitionType: {},
      competitionTypes: [],
      type: "insert",
      nameFilter: "",
      files: [],
      teamAllocation: {
        association: null,
        availableTeams: [],
        teams: [],
      },
      voterAllocation: {
        association: null,
        availableTeams: [],
        teams: [],
      },
      refAppointment: {
        association: null,
        availableTeams: [],
        teams: [],
      },
      assignmentAllocation: {
        type: undefined,
        assignees: [],
        availableAssignees: [],
      },
      ageLevelFilter: null,
      nameUpdated: false,
      editingRP: false,
      showAdditionalDetails: false,
      competition: {
        name: null,
        hasPools: false,
        orgtree: {
          association: { _id: null, name: null },
        },
        teams: [],
        type: null,
        season: null,
        seasonTag: undefined,
        ageLvl: null,
        gender: null,
        allowScoring: null,
        noMatchesForFinalEligibility: null,
        regularSeasonRounds: null,
        finalSeasonRounds: null,
        competitionType: {
          _id: null,
          name: null,
        },
        drawbuilder: {
          defaultStartTime: null,
          defaultStartDay: null,
          daysBetweenRounds: null,
        },
        display: {
          hideRounds: [],
          public: false,
          result: false,
          ladder: false,
          teamlists: false,
        },
        laddertemplate: {
          name: null,
          pointsWin: null,
          pointsLoss: null,
          pointsDraw: null,
          pointsBye: null,
          pointsFF: null,
          pointsWO: null,
          ffCountAsWin: false,
          ffCountAsLoss: false,
          ffCountAsPlayed: false,
          defaultScoreFFReceived: null,
          woCountAsPlayed: false,
          byeCountAsPlayed: false,
          sortBy: null,
        },
        meta: {
          elite: false,
          enableAwards: false,
          awardsSettings: {
            periodVoting: undefined,
            periodRanges: undefined,
            teamOfYear: false,
            endOfSeasonVoting: [],
            demerits: false,
          },
          playerPoints: {
            use: false,
            max: 0,
          },
          venue: {
            _id: 0,
            name: "",
            suburb: "",
            venueTimezone: "",
          },
          numberOfFields: undefined,
          maxNoTeams: undefined,
        },
        isActive: true,
      },
      allLadderTemplates: [],
      allFinalTemplates: finalTemplates,
      allvenues: [],
      allAssociations: [],
      allCompTypes: [],
      competitionRules: {
        name: { required: true, min: 3, message: errormsg.input_option },
        association: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
          validator: assocValidator,
        },
        competitionType: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
          validator: compTypeValidator,
        },
        type: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
          validator: typeValidator,
        },
        ageLvl: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        finalsType: {
          required: false,
          message: errormsg.select_option,
          trigger: "change",
        },
        allowScoring: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        gender: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        season: {
          type: "number",
          required: true,
          trigger: "blur",
          validator: positiveIntValidate,
        },
        seasonTag: {
          type: "number",
          required: false,
          trigger: "blur",
          validator: positiveIntValidate,
        },
        noMatchesForFinalEligibility: {
          type: "number",
          required: true,
          trigger: "blur",
          message: errormsg.gte_positive_zero,
          validator: positiveIntZeroValidate,
        },
        regularSeasonRounds: {
          type: "number",
          required: true,
          message: errormsg.gte_positive_zero,
          trigger: "blur",
          validator: positiveIntZeroValidate,
        },
        finalSeasonRounds: {
          type: "number",
          required: true,
          message: errormsg.gte_positive_number,
          trigger: "blur",
          validator: positiveIntZeroValidate,
        },
        defaultStartTime: {
          required: true,
          message: errormsg.input_option,
          validator: dstValidator,
          trigger: "blur",
        },
        daysbetweenrounds: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: dbrValidator,
        },
        defaultstartday: {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: validateDefaultStartDay,
        },
        periods: {
          required: true,
          message: errormsg.gte_positive_zero,
          trigger: "blur",
          validator: positiveIntZeroValidate,
        },
        periodLength: {
          required: true,
          message: errormsg.gte_positive_zero,
          trigger: "blur",
          validator: positiveIntZeroValidate,
        },
        "meta.venue._id": {
          required: true,
          validator: validateVenue,
          message: errormsg.select_option,
          trigger: "change",
        },
        "meta.numberOfFields": {
          required: true,
          message: errormsg.input_option,
          trigger: "blur",
          validator: positiveIntValidate,
        },
      },
      voterRules: {
        memberId: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
        quarter: {
          required: true,
          message: errormsg.select_option,
          trigger: "change",
        },
      },
      add: {
        memberId: null,
        votedOn: false,
        quarter: undefined,
      },
      awardTeam: [],
      newVoters: [],
      assignedVoters: [],
      votesSubmitted: [],
      votingOpen: undefined,
      refTeam: [],
      newRefs: [],
      assignedRefs: [],
      pageSize: 20,
      tableProps: {
        border: true,
      },
      paginationProps: {
        pageSizes: [20, 50, 100],
      },
      seasonTags,
      currentSeason,
      ageLevels,
      roles,
      isActiveChange: false,
      isActiveUpdateDialog: false,
      search: "",
      members: [],
      playerTeams: [],
      playerMatches: [],
      currentMember: {
        type: "demerits",
        player: {
          _id: undefined,
          name: "",
          position: undefined,
        },
        team: {
          _id: undefined,
          name: "",
        },
        match: {
          _id: undefined,
          homeTeam: undefined,
          awayTeam: undefined,
        },
        competition: undefined,
        round: {
          name: "",
          number: undefined,
        },
        votes: undefined,
      },
      readOnly: false,
      club: {
        meta: {
          avatar: "",
        },
      },
      accept: "image/png,image/jpg,image/jpeg",
      extensions: "jpg,jpeg,png",
      compPrivateFinderLink: "",
      competitionId: "",
    };
  },
  async mounted() {
    try {
      this.$store.commit("root/loading", true);
      const { type, comp, id } = this.$route.params;
      this.type = type || (id !== "add" ? "update" : "insert");
      if (id && id !== "" && id !== "add") {
        this.competitionId = id;
        const [compRes, associationsRes, settingsRes, additionalDetailsRes] = await Promise.all([
          this.$http.get(`/nrl/api/v1/admin/competitions/${id}/isActive`),
          this.$http.get("/nrl/api/v1/admin/associations/"),
          this.$http.get("/nrl/api/v1/admin/competitions-settings/"),
          this.$http.get(`/nrl/api/v1/admin/competitions/${id}/additional-details`),
        ]);
        this.allCompTypes = settingsRes.data.data;
        this.allAssociations = associationsRes.data.data;
        this.competition = _.merge({}, this.competition, compRes.data.data);
        await this.getCompetitionTypes();
        this.handleCompetitionTypeChange();
        this.showAdditionalDetails = additionalDetailsRes.data.showAdditionalDetails;
        this.compPrivateFinderLink = this.privateFinderLink();
        if (this.competition && this.competition.orgtree && this.competition.orgtree.association) {
          this.$http
            .get(
              `/nrl/api/v1/admin/competition-types/association/${this.competition.orgtree.association._id}`
            )
            .then((response) => {
              this.competitionTypes = response.data.data;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
              this.$customError();
            });
        }
        /**
         * Mark if this competition orgtree.association._id is not same as current role entityId
         */
        if (
          this.competition.orgtree.association._id !==
            this.$store.getters["user/activeRole"].entity._id &&
          this.$store.getters["user/activeRole"].type === 4
        )
          this.readOnly = true;

        // Transfer Team Allocations
        if (compRes.data.data && compRes.data.data.teams) {
          this.teamAllocation.availableTeams = compRes.data.data.teams;
          this.teamAllocation.teams = compRes.data.data.teams.map((t) => t._id);
        }
        // Transfer Voter Team Allocations
        if (compRes.data.data.awardVoters) {
          this.voterAllocation.availableTeams = compRes.data.data.awardVoters;
          this.voterAllocation.teams = compRes.data.data.awardVoters.map((t) => t._id);
        }

        // Transfer Ref Team Allocations
        if (compRes.data.data.refs) {
          this.refAppointment.availableTeams = compRes.data.data.refs;
          this.refAppointment.teams = compRes.data.data.refs.map((t) => t._id);
        }

        // TFA only: bind venue into remote list
        if (this.competition.meta.venue) {
          this.allvenues = [this.competition.meta.venue];
        }
      } else {
        if (comp) {
          this.competition = _.merge({}, this.competition, comp);
          await this.getCompetitionTypes();
          this.handleCompetitionTypeChange();
          /**
           * Mark if this competition orgtree.association._id is not same as current role entityId
           */
          if (
            this.competition.orgtree.association._id !==
              this.$store.getters["user/activeRole"].entity._id &&
            this.$store.getters["user/activeRole"].type === 4
          )
            this.readOnly = true;

          if (comp.teams) {
            this.teamAllocation.availableTeams = comp.teams;
            this.teamAllocation.teams = comp.teams.map((t) => t._id);
          }
          if (comp.awardVoters) {
            this.voterAllocation.availableTeams = comp.awardVoters;
            this.voterAllocation.teams = comp.awardVoters.map((t) => t._id);
          }

          if (comp.refs) {
            this.refAppointment.availableTeams = comp.refs;
            this.refAppointment.teams = comp.refs.map((t) => t._id);
          }

          // TFA only: bind venue into remote list
          if (this.competition.meta.venue) {
            this.allvenues = [this.competition.meta.venue];
          }
        }
        const [associationsRes, settingsRes, seasonRes, additionalDetailsRes] = await Promise.all([
          this.$http.get("/nrl/api/v1/admin/associations/"),
          this.$http.get("/nrl/api/v1/admin/competitions-settings/"),
          this.$http.get("/nrl/api/v1/admin/seasons/current"),
          this.$http.get(
            `/nrl/api/v1/admin/competitions/${this.competition._id}/additional-details`
          ),
          // this.$http.get(`/nrl/api/v1/admin/competitions/${id}`),
        ]);
        this.allCompTypes = settingsRes.data.data;
        this.allAssociations = associationsRes.data.data;
        this.competition.season = seasonRes.data.data.season;
        this.showAdditionalDetails = additionalDetailsRes.data.showAdditionalDetails;
      }
      this.$store.commit("root/loading", false);
      EventBus.$on("updateCompetition", (updatedComp) => {
        this.competition = updatedComp;
        this.competitionId = this.competition._id;
        this.teamAllocation.availableTeams = updatedComp.teams;
        this.teamAllocation.teams = updatedComp.teams.map((t) => t._id);
        this.voterAllocation.availableTeams = updatedComp.awardVoters;
        this.voterAllocation.teams =
          updatedComp.awardVoters && updatedComp.awardVoters.length
            ? updatedComp.awardVoters.map((t) => t._id)
            : [];
        this.refAppointment.availableTeams = updatedComp.refs;
        this.refAppointment.teams =
          updatedComp.refs && updatedComp.refs.length ? updatedComp.refs.map((t) => t._id) : [];
      });

      const voteRes = await this.$http.get(
        `/nrl/api/v1/admin/comp/${this.competition._id}/vote-status`
      );
      let d = [];
      if (voteRes.data && voteRes.data.data) d = voteRes.data.data;
      const { compAssignments, uniqueVotingIds } = d;
      if (compAssignments.length) {
        const sa = [];
        const newArr = compAssignments.map((v) => {
          const { memberId, status, quarter } = v;
          const statusArr = status.split("_");
          const votingOpen = statusArr.includes("open");
          sa.push(votingOpen);
          return { memberId, votingOpen, quarter };
        });
        const unique = sa.filter((v, i, s) => {
          return s.indexOf(v) === i;
        });
        const voteStatus = unique.length === 1 ? unique[0] : undefined;
        this.votingOpen = voteStatus;
        this.votesSubmitted = uniqueVotingIds;
        this.assignedVoters = newArr;
      }

      // set competition voting team if exists
      const teamId = this.voterAllocation.teams[0];
      if (teamId) {
        const res = await this.$http.get(`/nrl/api/v1/admin/teamlists/players/${teamId}`);
        this.awardTeam = res.data && res.data.data ? res.data.data : [];
      }
      this.assignmentAllocation.availableAssignees = this.awardTeam;

      // set competition ref team if exists
      const refTeamIds = this.refAppointment.teams;
      if (refTeamIds && refTeamIds.length) {
        refTeamIds.forEach(async (team) => {
          const res = await this.$http.get(`/nrl/api/v1/admin/teamlists/players/${team}`);
          const refArr = res.data && res.data.data ? res.data.data : [];
          this.refTeam = _.merge(this.refTeam, refArr);
        });
      }

      // populate table of previously assigned voters
      if (this.assignedVoters && this.assignedVoters.length) {
        this.assignedVoters.forEach((v) => {
          const { memberId, quarter, votingOpen } = v;
          const profArr = this.awardTeam.find((p) => {
            return p._id === memberId;
          });
          const profile = profArr && profArr.profile ? profArr.profile : null;
          const votedOn = this.votesSubmitted.includes(memberId);
          this.newVoters.push({
            memberId,
            votingOpen,
            votedOn,
            profile,
            quarter,
          });
        });
      }
    } catch (e) {
      this.$store.commit("root/loading", false);
      this.$customError();
    }
  },
  methods: {
    handleCompetitionTypeChange(compTypechange = false) {
      // find the comp type from this.competitionTypes  where _id is same
      const selectedType = this.competitionTypes.find(
        (type) => type._id === this.competition.competitionType._id
      );
      if (selectedType) {
        this.selectedCompetitionType = selectedType;
        if (this.selectedCompetitionType.teamListTypes) {
          // update team list types to comp types
          this.allCompTypes = this.selectedCompetitionType.teamListTypes;
        }

        // if this.selectedCompetitionType doesn't allow scoring we disable allow scoring in competition
        if (selectedType.isAllowedToScore === false) {
          this.competition.allowScoring = false;
          this.competition.display.result = false;
          this.competition.noMatchesForFinalEligibility = 0;
        }

        //set age levels if mentioned under comp type
        this.ageLevels = ageLevels;
        if (selectedType.ageLevels && selectedType.ageLevels.length > 0) {
          const array = [];
          this.ageLevels.forEach((ageLevel) => {
            selectedType.ageLevels.forEach((level) => {
              if (level.name === ageLevel.name) {
                array.push(ageLevel);
              }
            });
          });
          if (compTypechange) {
            this.competition.ageLvl = null;
            this.competition.type = null;
          }
          this.ageLevels = array;
        }

        // if this.isAllowedToUseLadders doesn't allow ladders we disable following in competition
        if (selectedType.isAllowedToUseLadders === false) {
          // this.competition.finalSeasonRounds = 0
          // this.competition.finalsType = undefined
          this.competition.laddertemplate.pointsWin = 0;
          this.competition.laddertemplate.pointsLoss = 0;
          this.competition.laddertemplate.pointsDraw = 0;
          this.competition.laddertemplate.pointsBye = 0;
          this.competition.laddertemplate.pointsFF = 0;
          this.competition.laddertemplate.pointsWO = 0;
          this.competition.laddertemplate.defaultScoreFFReceived = 0;
          this.competition.laddertemplate.ffCountAsWin = false;
          this.competition.laddertemplate.ffCountAsLoss = false;
          this.competition.laddertemplate.woCountAsPlayed = true;
          this.competition.laddertemplate.byeCountAsPlayed = true;
          this.competition.laddertemplate.ffCountAsPlayed = true;
        }
        // if this.isAllowedToUseFinals is set to false we disable following in competition
        if (selectedType.isAllowedToUseFinals === false) {
          this.competition.finalSeasonRounds = 0;
          this.competition.finalsType = undefined;
          this.competition.noMatchesForFinalEligibility = 0;
        }

        // if this.isAllowedToDisplayLaddersAndResults is set to false we disable following
        if (selectedType.isAllowedToDisplayLaddersAndResults === false) {
          this.competition.display.ladder = false;
          this.competition.display.result = false;
        }
      }
    },
    async getCompetitionTypes() {
      try {
        const response = await this.$http.get(
          `/nrl/api/v1/admin/competition-types/association/${this.competition.orgtree.association._id}`
        );
        this.competitionTypes = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$customError();
      }
    },
    async handleAddVoter() {
      try {
        await new Promise((resolve, reject) => {
          this.$refs.voterRules.validate((valid) => (valid ? resolve() : reject()));
        });
        const { memberId, votedOn, quarter } = this.add;
        const { profile } = this.awardTeam.find((p) => {
          return p._id === memberId;
        });
        const voter = {
          memberId,
          votedOn,
          profile,
          quarter,
        };
        this.newVoters.push(voter);
        this.$set(this.add, "memberId", null);
        this.$set(this.add, "votedOn", false);
      } catch (error) {
        this.$customError("Could not add Voter");
        throw new Error("Could not add Voter");
      }
    },
    async notifyJudges() {
      this.$store.commit("root/loading", true);
      try {
        const { assignedVoters } = this;
        const matchName = "Quarterly Voting";
        await this.$http.post("/nrl/api/v1/admin/notify-judges", { assignedVoters, matchName });
        this.$store.commit("root/loading", false);
        this.$customSuccess("Emails sent to assigned judges");
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError("Unable to send email to judges/s");
      }
    },
    fullname(p) {
      return p.profile && p.profile.firstName && p.profile.lastName
        ? `${p.profile.firstName} ${p.profile.lastName}`
        : `Member: ${p.memberId}`;
    },
    removeFromVoterList(row) {
      this.$confirm(
        "Are you sure you want to remove this player from the team list",
        "Remove player",
        { confirmButtonText: "OK", cancelButtonText: "Cancel" }
      )
        .then(() => {
          this.newVoters.splice(
            this.newVoters.findIndex((p) => p.memberId === row.memberId),
            1
          );
        })
        .catch(() => {});
    },
    async submitVoterAssignment() {
      try {
        await this.$confirm(
          "Submitting this team list will override existing one. Do you want to continue?",
          "Submit Team List",
          { confirmButtonText: "OK", cancelButtonText: "Cancel" }
        );
        this.$store.commit("root/loading", true);
        const { newVoters, votingOpen, assignedVoters, votesSubmitted } = this; // member/s assigned to quarter voting prior & current member/s selected in form to update

        // no judges assigned or assigning new judge/s
        newVoters.forEach((voters) => {
          voters.votingOpen = votingOpen;
        });
        const newAssignments = newVoters.filter((v) => {
          const { memberId, votingOpen } = v;
          return !assignedVoters.find((av) => {
            return av.memberId === memberId && av.votingOpen === votingOpen;
          });
        });
        const unassignments = assignedVoters.filter((v) => {
          const { memberId } = v;
          return !newVoters.find((nv) => {
            return nv.memberId === memberId;
          });
        });
        const assignmentSubmissions = [];
        newAssignments.forEach((na) => {
          const insert = {
            comp: {
              id: this.competition._id,
              name: this.competition.name,
            },
          };
          const { memberId, votingOpen, quarter } = na;
          insert.memberId = memberId;
          const vs = votesSubmitted.includes(memberId) ? "voted" : "novote";
          insert.status = votingOpen ? `voter_assigned_open_${vs}` : `voter_assigned_closed_${vs}`;
          insert.quarter = quarter;
          assignmentSubmissions.push(insert);
        });
        unassignments.forEach((na) => {
          const insert = {
            comp: {
              id: this.competition._id,
              name: this.competition.name,
            },
          };
          const { memberId, votingOpen, quarter } = na;
          insert.memberId = memberId;
          const vs = votesSubmitted.includes(memberId) ? "voted" : "novote";
          insert.status = votingOpen
            ? `voter_unassigned_open_${vs}`
            : `voter_unassigned_closed_${vs}`;
          insert.quarter = quarter;
          assignmentSubmissions.push(insert);
        });
        await this.$http.post("/nrl/api/v1/admin/comp-assignment", { assignmentSubmissions });
        this.$store.commit("root/loading", false);
        this.$router.go(-1);
        this.$customSuccess("Voters Successfully Assigned");
        return true;
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError();
      }
    },
    async searchMember() {
      try {
        const res = await this.$http.get(
          `/nrl/api/v1/admin/${this.competition._id}/players-matches?search=${this.search}`
        );
        this.members = res.data.data ? res.data.data : [];
      } catch (error) {
        this.$customError();
      }
    },
    selectMember(currentRow) {
      this.currentMember.player._id = _.get(currentRow, "_id._id") || undefined;
      this.currentMember.player.name = _.get(currentRow, "_id.name") || undefined;
      const teamsList = _.get(currentRow, "matches") || [];
      const obj = {};
      teamsList.forEach((m) => (obj[m.team._id] = m.team.name));

      // clear fields
      this.playerTeams = [];
      this.currentMember.player.position = undefined;
      this.currentMember.team._id = undefined;
      this.currentMember.team.name = "";
      this.playerMatches = [];
      this.currentMember.match._id = undefined;
      this.currentMember.match.homeTeam = undefined;
      this.currentMember.match.awayTeam = undefined;
      this.currentMember.competition = undefined;
      this.currentMember.round.name = "";
      this.currentMember.round.number = undefined;
      this.currentMember.votes = undefined;

      for (let [id, name] of Object.entries(obj)) {
        this.playerTeams.push({ id, name });
      }
    },
    teamSelect(v) {
      const team = this.playerTeams.find((t) => t.name === v);
      this.currentMember.team._id = parseInt(team.id);

      const playerDetails = this.members.find((m) => m._id._id === this.currentMember.player._id);
      const matches = playerDetails.matches.filter(
        (m) => m.team._id === this.currentMember.team._id
      );
      this.playerMatches = matches;
    },
    matchSelect(v) {
      const match = this.playerMatches.find((m) => m.match._id === v);
      const game = match.match;
      const { name, number } = match.round;
      const { position } = match;
      const { home, away } = game;
      this.currentMember.player.position = position;
      this.currentMember.match.homeTeam = home;
      this.currentMember.match.awayTeam = away;
      this.currentMember.competition = this.competition._id;
      this.currentMember.round.name = name;
      this.currentMember.round.number = number;
    },
    async deductPoints() {
      try {
        await this.$confirm(
          "Are you sure you want to deduct points from this player? This cannot be undone.",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        const { currentMember } = this;
        this.$store.commit("root/loading", true);
        await this.$http.post("/nrl/api/v1/admin/player/demerits", { currentMember });
        this.$store.commit("root/loading", false);
        this.$router.go(-1);
        this.$customSuccess();
        return true;
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError();
      }
    },
    async updateCompetition() {
      this.$refs.competitionRules.validate(async (valid) => {
        try {
          if (!valid) return false;
          this.$store.commit("root/loading", true);
          this.handleCompetitionTypeChange();
          const payload = {
            ...this.competition,
          };
          const venueCheck = payload?.meta?.venue?._id;
          if (!venueCheck) delete payload.meta.venue;
          if (this.type === "update") {
            // Update competition
            if (!this.nameUpdated) delete payload.name;
            delete payload.teams;
            if (this.isSuperAdminTFA && this.isActiveChange) payload.expired = !payload.isActive;
            const url = `/nrl/api/v1/admin/competitions/${this.competition._id}`;
            const res = await this.$http.put(url, payload);

            // reflect update to `display` once competition is set inactive
            const { display } = res.data.data;
            this.competition.display = display;
            this.isActiveChange = false;
          } else {
            // Else insert comp
            const url = "/nrl/api/v1/admin/competitions/";
            const response = await this.$http.post(url, payload);
            const _id = response.data.data;
            this.$router.push({
              name: "competitions.update",
              params: {
                type: "update",
                comp: { _id, ...this.competition },
                id: _id,
              },
            });
          }
          this.compPrivateFinderLink = this.privateFinderLink();
          this.$store.commit("root/loading", false);
          this.$customSuccess();
          return true;
        } catch (e) {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      });
    },
    cancelForm() {
      this.$router.push({
        name: "competitions.list",
      });
    },
    filter(query) {
      if (query !== "" && query.length > 2) {
        setTimeout(() => {
          this.$http
            .post("/nrl/api/v1/admin/venues/search", { criteria: query })
            .then((response) => {
              this.allvenues = response.data.data;
            })
            .catch(() => {});
        }, 200);
      } else {
        this.allvenues = [];
      }
    },
    async updateCompetitionAndTeams() {
      this.$refs.competitionRules.validate(async (valid) => {
        if (valid) {
          // Update competition
          this.$store.commit("root/loading", true);

          let update = {
            ...this.competition,
          };
          if (!this.nameUpdated) delete update.name;
          if (this.isSuperAdminTFA && this.isActiveChange) update.expired = !update.isActive;

          //Bulk Teams isActive Update
          const editPromises = [];
          if (update.teams && update.teams.length > 0) {
            update.teams.forEach((team) => {
              team.isActive = update.isActive;
              if (this.isSuperAdminTFA && this.isActiveChange) team.expired = !update.isActive;
              editPromises.push(this.$http.put(`/nrl/api/v1/admin/teams/${team._id}`, team));
            });

            delete update.teams;
            editPromises.push(
              this.$http.put(`/nrl/api/v1/admin/competitions/${this.competition._id}`, update)
            );
            try {
              await Promise.all(editPromises);
              this.isActiveUpdateDialog = false;
              this.isActiveChange = false;
              // this.$customSuccess();
              this.$store.commit("root/loading", false);
              this.$router.go();
            } catch (e) {
              this.$store.commit("root/loading", false);
              this.$customError();
            }
          } else {
            this.updateCompetition();
            this.isActiveUpdateDialog = false;
            this.$router.go();
          }
        }
      });
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response.status === "success") {
          this.$store.commit("root/loading", false);
          this.competition.meta.avatar = newFile.response.url;
          if (this.type === "update") {
            const { avatar } = this.competition.meta;
            const { _id } = this.competition;
            this.$store.commit("root/loading", true);
            const update = { meta: { avatar } };
            const url = `/nrl/api/v1/admin/competitions/${_id}`;
            this.$http
              .put(url, update)
              .then(() => {
                this.$store.commit("root/loading", false);
                this.$customSuccess();
              })
              .catch(() => {
                this.$store.commit("root/loading", false);
                this.$customError();
              });
          }
        } else {
          this.$store.commit("root/loading", false);
          this.$customError();
        }
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.$customError(errormsg.image_format);
        return prevent();
      }
      if (this.$route.params.type === "insert") {
        return prevent();
      }
      this.$refs.upload.active = true;
      this.$store.commit("root/loading", true);
      return true;
    },
    displayPlaceholder(event) {
      event.target.src = require("../../assets/shield_black.svg");
    },
    privateFinderLink() {
      // convert to base64
      const data = btoa(
        JSON.stringify({
          entityId: this.competitionId,
          entityType: "competition",
        })
      );
      const portalURL =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://test-profile.mysideline.xyz/"
          : "https://profile.mysideline.com.au/";
      const baseLink = new URL("/register/compsearch", portalURL);
      baseLink.searchParams.set("data", data);
      return String(baseLink);
    },
  },
  computed: {
    enableAwardsSettings() {
      const user = this.$store.getters["user/email"];

      if (this.competition && this.competition.meta && this.competition.meta.awardsSettings) {
        const { adminEmailList } = this.competition.meta.awardsSettings;

        // Check if adminEmailList is an array and contains the user's email
        if (Array.isArray(adminEmailList) && adminEmailList.includes(user)) {
          return true; // User's email is in the adminEmailList
        }
      }

      return false; // User's email is not found or awards settings are not enabled
    },
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === nationals.TFA;
    },
    availableVoters() {
      return this.awardTeam
        .filter((p) => {
          return !this.newVoters.find(({ memberId }) => {
            return p._id === memberId;
          });
        })
        .sort();
    },
    availableRefs() {
      return this.refTeam
        .filter((p) => {
          return !this.newRefs.find(({ memberId }) => {
            return p._id === memberId;
          });
        })
        .sort();
    },
    competitionName: {
      get() {
        return this.competition.name;
      },
      set(value) {
        this.nameUpdated = true;
        this.competition.name = value;
      },
    },
    competitionType: {
      get() {
        return this.competition.competitionType._id;
      },
      set(competitionTypeId) {
        const foundCompetitionType = this.competitionTypes.find(
          (obj) => obj._id === competitionTypeId
        );
        if (foundCompetitionType) {
          const competitionType = {
            _id: foundCompetitionType._id,
            name: foundCompetitionType.name,
          };
          this.competition.competitionType = competitionType;
        }
      },
    },
    association: {
      get() {
        return this.competition.orgtree.association._id;
      },
      set(associd) {
        const foundAssoc = this.allAssociations.find((obj) => obj._id === associd);
        if (foundAssoc) {
          const { orgtree } = foundAssoc;
          orgtree.association = {
            _id: foundAssoc._id,
            name: foundAssoc.name,
          };
          this.competition.orgtree = orgtree;
        }
      },
    },
    currentAssociation() {
      const assoc_id =
        this.competition &&
        this.competition.orgtree &&
        this.competition.orgtree.association &&
        this.competition.orgtree.association._id;
      if (this.allAssociations && Array.isArray(this.allAssociations)) {
        const foundAssoc = this.allAssociations.find((a) => a._id === assoc_id);
        return foundAssoc;
      }
      return undefined;
    },
    rlPermissionedAssociation() {
      if (
        this.currentAssociation &&
        this.currentAssociation.permissions &&
        this.currentAssociation.permissions.teamRegPermAllow
      )
        return true;
      return false;
    },
    defaultStartDay: {
      get() {
        return this.competition.drawbuilder.defaultStartDay;
      },
      set(value) {
        this.competition.drawbuilder.defaultStartDay = this.moment(value).valueOf();
      },
    },
    daysBetweenRounds: {
      get() {
        return this.competition.drawbuilder.daysBetweenRounds;
      },
      set(value) {
        this.competition.drawbuilder.daysBetweenRounds = parseInt(value);
      },
    },
    isSuperAdminNRL() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 32;
    },
    isSuperAdminTFA() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.superAdmin && national_id === 31;
    },
    isStateAdmin() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.stateAdmin;
    },
    isAssociationAdmin() {
      const { type, national_id } = this.$store.getters["user/activeRole"];
      return type === roles.associationAdmin;
    },
    isTFA() {
      return this.$store.getters["user/activeRole"].national_id === 31;
    },
    clearance() {
      return this.$store.getters["user/activeRole"].type;
    },
    currentLogo() {
      return this.competition.meta && this.competition.meta.avatar
        ? this.competition.meta.avatar
        : false;
    },
    uploadURL() {
      return `${process.env.VUE_APP_API_URL}/nrl/api/v1/admin/competition/logo/upload`;
    },
    uploadHeaders() {
      return this.$store.getters["user/uploadHeaders"];
    },
    competitionData() {
      return {
        competition_id: this.competition._id,
      };
    },
  },
  watch: {
    $route(to) {
      const { id, comp, type } = to.params;
      this.type = type;
      this.id = id;
      this.competition = { ...this.competition, ...comp };
    },
    "competition.meta.venue._id": function (id) {
      const venue = this.allvenues.find((x) => x._id === id);
      if (venue) {
        this.competition.meta.venue.name = venue.name;
        this.competition.meta.venue.venueTimezone = venue.venueTimezone;
        if (venue.address && venue.address.suburb)
          this.competition.meta.venue.suburb = venue.address.suburb;
        if (venue.suburb) this.competition.meta.venue.suburb = venue.suburb;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text-helper {
  line-height: 20px;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: -15px;
  color: $secondary;
}
::v-deep .el-input-number.is-without-controls .el-input__inner {
  text-align: left;
}
.el-input,
.el-select,
.el-input-number {
  width: 100%;
}

.btn-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  text-align: left;
}

.transfer-box {
  text-align: center;
  .transfer {
    margin: 0 auto;
  }
}

.round-checkboxes {
  border-radius: 200px;
}
.upload-img {
  text-align: center;
  margin: 0 auto;
}
.upload-btn {
  margin-top: 12px;
}
</style>
