<template>
  <div class="d-flex-column">
    <el-row :gutter="20" style="margin: 10px 0px 15px 5px !important">
      <el-col :span="12">
        <div class="heading">From Club</div>
        <el-select v-model="inputVal.fromClub" placeholder="Select a club" clearable filterable>
          <el-option v-for="{ _id, name } in fromClubs" :label="name" :key="_id" :value="_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <div class="heading">To Club</div>
        <el-select v-model="inputVal.toClub" placeholder="Select a club" clearable filterable>
          <el-option v-for="{ _id, name } in toClubs" :label="name" :key="_id" :value="_id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <!-- <div class="heading">From Club</div>
    <el-select v-model="inputVal.fromClub" placeholder="Select a club">
      <el-option v-for="fileType in fileTypes"
        :label="fileType.name"
        :key="fileType.type"
        :value="fileType.type"
        :default="fileType.default">
      </el-option>
    </el-select>
    <div class="heading">To Club</div>
    <el-select v-model="inputVal.toClub" placeholder="Select a club">
      <el-option v-for="fileType in fileTypes"
        :label="fileType.name"
        :key="fileType.type"
        :value="fileType.type"
        :default="fileType.default">
      </el-option>
    </el-select> -->

    <CheckBoxGroup
      :choices="[
        { name: 'Male', type: 'Male' },
        { name: 'Female', type: 'Female' },
        { name: 'All', type: 'All' },
      ]"
      :min="0"
      :max="1"
      name="Gender"
      v-model="inputVal.gender"
    />

    <CheckBoxGroup :choices="ageLevels" :min="0" name="Age Levels" v-model="inputVal.ageLvl" />

    <CheckBoxGroup
      :choices="[
        { name: 'Clearance', type: 'clearance' },
        { name: 'Permit', type: 'permit' },
      ]"
      :min="0"
      :max="1"
      name="Type"
      v-model="inputVal.type"
    />
    <CheckBoxGroup
      :choices="this.clearancePermitStatuses"
      :min="0"
      :max="1"
      name="Status"
      v-model="inputVal.status"
    />
    <CheckBoxGroup
      :choices="[
        { name: 'Yes', type: 'yes' },
        { name: 'No', type: 'no' },
      ]"
      :min="0"
      :max="1"
      name="Requires Action"
      v-model="inputVal.requiresAction"
    />
    <CheckBoxGroup
      :choices="[
        { name: 'Ascending', type: 'ascending' },
        { name: 'Descending', type: 'descending' },
      ]"
      :min="0"
      :max="1"
      name="Clearance ID Order"
      v-model="inputVal.clearanceIDOrder"
    />
    <div class="mt-2 d-flex-column">
      <div class="m-1">
        <el-button @click="clear" class="full-width button-invisible">Clear</el-button>
        <el-button @click="submit" type="primary" class="full-width">Apply Filters</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxGroup from "@/components/CheckboxGroup.vue";
import { EventBus } from "../../bus";
import { memberTypes, ageLevels, nationals, clearancePermitStatuses } from "../../utils/constants";

export default {
  name: "FilterPage",
  components: { CheckBoxGroup },
  props: ["value"],
  methods: {
    submit() {
      this.$emit("submit", this.inputVal);
    },
    clear() {
      this.$set(this.inputVal, "type", []);
      this.$set(this.inputVal, "status", []);
      this.$set(this.inputVal, "requiresAction", []);
      this.$set(this.inputVal, "clearanceIDOrder", []);
      this.$set(this.inputVal, "gender", []);
      this.$set(this.inputVal, "ageLvl", []);
      this.$set(this.inputVal, "fromClub", undefined);
      this.$set(this.inputVal, "toClub", undefined);
      EventBus.$emit("clear");
      this.$emit("clear", this.inputVal);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  data() {
    return {
      fromClubs: [],
      toClubs: [],
      inputVal: {
        type: [],
        status: [],
        requiresAction: [],
        clearanceIDOrder: [],
        gender: [],
        ageLvl: [],
        fromClub: undefined,
        toClub: undefined,
      },
      ageLevels: ageLevels.map((age) => ({ name: age.name, type: age.name })),
      clearancePermitStatuses: clearancePermitStatuses,
    };
  },
  mounted() {
    const { type, status, requiresAction, clearanceIDOrder, gender, ageLvl, fromClub, toClub } =
      this.$store.state.clearancePermit.filters;
    this.$set(this.inputVal, "type", type || []);
    this.$set(this.inputVal, "requiresAction", requiresAction || []);
    this.$set(this.inputVal, "clearanceIDOrder", clearanceIDOrder || []);
    this.$set(this.inputVal, "status", status || []);
    this.$set(this.inputVal, "gender", gender || []);
    this.$set(this.inputVal, "ageLvl", ageLvl || []);
    this.$set(this.inputVal, "fromClub", fromClub || undefined);
    this.$set(this.inputVal, "toClub", toClub || undefined);

    this.$http.get("nrl/api/v1/admin/clearance-permits/clubs").then((res) => {
      const { fromClubs, toClubs } = res.data.data;
      this.fromClubs = fromClubs;
      this.toClubs = toClubs;
    });
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 5px 0px;
}
.el-select {
  width: 100%;
}
</style>
